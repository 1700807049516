























































































































































































































import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { vehiculoModule } from '@/store/modules/vehiculos';
import { neumaticoModule } from '@/store/modules/neumaticos';
import { appModule } from '@/store/modules/app';
import { inspeccionModule } from '@/store/modules/inspecciones';
import ServicioVehiculo from '@/components/ServicioVehiculo.vue'
import axios from "axios";
import VehicleTiresData from "@/components/vehicleTiresData/index.vue";

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog,
    VehicleTiresData,
    ServicioVehiculo
  }
})
export default class VehiculoForm extends Vue {
  private errors = [];
  private title = '';
  private data: [];
  private dialog = false;
  private dialogTitle = "Neumáticos vehículo";
  private dialogText = "Vehiculo sin todos los neumáticos necesarios";
  public headers = [
    { text: 'Nombre', value: 'nombre' },
    { text: 'Fecha', value: 'fecha' },
    { text: '', value: 'actions', sortable: false }
  ];
  private URL: '';

  private addServicioNeumaticoModal= false;
  private modalTitle = 'Nuevo neumático';
  private inspeccionNeumatico= {
    agregado: false,
    bar_corregido: 0,
    presion: 0,
    mm_prof_3: 0,
    mm_prof_2: 0,
    mm_prof_1: 0,
    mm: 0,
    posicion: "",
    id: 0,
    n_serie: ""
  };

  get vehiculo() {
    return vehiculoModule.vehiculo;
  }

  get neumaticos() {
    return vehiculoModule.vehiculo.neumaticos;
  }

  get inspeccion() {
    return inspeccionModule.inspeccion;
  }

  get inspeccionNeumaticos() {
    return inspeccionModule.neumaticos;
  }

  get allTiposNeumaticos() {
    return neumaticoModule.tiposNeumatico;
  }

  cancelAddServicioNeumatico() {
    this.addServicioNeumaticoModal = false;
    (this.$refs.form_inspeccion_neumatico as any).resetValidation();
    this.inspeccionNeumatico= {
      agregado: false,
      bar_corregido: 0,
      presion: 0,
      mm_prof_3: 0,
      mm_prof_2: 0,
      mm_prof_1: 0,
      mm: 0,
      posicion: "",
      id: 0,
      n_serie: ""
    };
  }

  addServicioNeumatico(tireInfo) {
    console.log(tireInfo);
    this.inspeccionNeumatico= tireInfo;
    if(tireInfo.presion){
      this.inspeccionNeumatico.agregado= true;
    }
    console.log(this.inspeccionNeumatico);
    const neumatico= this.neumaticos.find(element => element.posicion == tireInfo.posicion);
    console.log(neumatico);
    if(neumatico){
      this.addServicioNeumaticoModal= true;
      this.modalTitle= this.$t("message.Añadir inspección neumático");
      this.inspeccionNeumatico.id= neumatico.id;
      this.inspeccionNeumatico.n_serie= neumatico.n_serie;
      this.inspeccionNeumatico.id_tipo_neumatico= neumatico.id_tipo_neumatico;
    }
    else{
      this.dialog= true;
      this.dialogTitle= this.$t("message.Neumático vehículo");
      this.dialogText = this.$t("message.No existe el neumático en el vehículo");
    }
    this.inspeccionNeumatico.posicion= tireInfo.posicion;
  }

  saveInspeccionNeumatico() {
    if((this.$refs.form_inspeccion_neumatico as Vue & { validate: () => boolean }).validate()){
      const inspeccion= {... this.inspeccionNeumatico};
      inspeccion.mm= (Number(inspeccion.mm_prof_1)+ Number(inspeccion.mm_prof_2)+ Number(inspeccion.mm_prof_3)) / 3;
      inspeccion.presion= inspeccion.bar_corregido;
      console.log(inspeccion);
      if(!this.inspeccionNeumatico.agregado){
        this.addNeumatico(inspeccion);
      }
      (this.$refs.form_inspeccion_neumatico as any).resetValidation();
      this.inspeccionNeumatico= {
        agregado: false,
        bar_corregido: 0,
        presion: 0,
        mm_prof_3: 0,
        mm_prof_2: 0,
        mm_prof_1: 0,
        mm: 0,
        posicion: "",
        id: 0,
        n_serie: ""
      };
      this.addServicioNeumaticoModal = false;
    }
  }

  addNeumatico(tireInfo) {
    this.inspeccionNeumaticos.push(tireInfo);
    console.log(this.inspeccionNeumaticos);
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  customFilter (item, queryText, itemText) {
          const textOne = item.nombre.toLowerCase()
          const searchText = queryText.toLowerCase()

          return textOne.indexOf(searchText) > -1
        }

  save() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      console.log(this.checkNeumaticosVehiculo(this.vehiculo.neumaticos));
      if(this.checkNeumaticosVehiculo(this.vehiculo.neumaticos)){
        this.dialog= true;
        this.dialogTitle= this.$t("message.Inspección vehículo");
        this.dialogText = this.$t("message.Es requerido realizar la inspección a todos los neumáticos");
      }
      else{
        const inspeccion= {... this.inspeccion};
        inspeccion.neumaticos= JSON.stringify(this.inspeccionNeumaticos);
        inspeccion.id_vehiculo= this.vehiculo.id;
        if(!inspeccion.km){
          inspeccion.km= 0;
        }
        const fecha= new Date().toISOString();
        inspeccion.fecha= fecha.split('T')[0]+" "+ fecha.split('T')[1].substring(0, 8);
        inspeccionModule.saveInspeccion(inspeccion);
      }
    }
  }

  checkNeumaticosVehiculo(neumaticos) {
    console.log(this.inspeccionNeumaticos);
    let error= false;
    neumaticos.map(tireData => {
      if(!this.inspeccionNeumaticos.find(element => element.posicion == tireData.posicion)){
        error= true;
      }
    });
    return error;

  }

  getVehiculoById() {
    vehiculoModule.getVehiculoById(parseInt(this.$route.params.id));
  }

  getNeumaticosByVehiculoId() {
    vehiculoModule.getNeumaticosByIdVehiculo(parseInt(this.$route.params.id));
  }

  cancel() {
    this.$router.push(`/base/${this.vehiculo.id_base}/vehiculo/${this.vehiculo.id}`);
    /*if(this.checkNeumaticosVehiculo(this.vehiculo.neumaticos)){
      this.dialog = true;
    }
    else{
      this.$router.push(`/vehiculo/${this.vehiculo.id}`);
    }*/
  }

  onConfirm() {
    this.dialog = false;
  }

  onCancel() {
    this.dialog = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    appModule.setModal(false);
    this.getVehiculoById();
    inspeccionModule.getInspeccionById(this.$route.params.id_inspeccion);
    neumaticoModule.getTiposNeumatico();
  }

  mounted() {
    if (this.$route.params.id_inspeccion) {
      //this.$refs.form.resetValidation();
      this.title = 'Editar inspección';
    } else{
      this.title = this.$t("message.Nueva inspección");
    }
  }
}
