import { User, UserInfo } from "@/types";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { login, getData, postData, putData, deleteData } from "@/utils/backend-api";
import {
  getUser,
  getToken,
  setToken,
  setUser,
  cleanSession
} from "@/utils/app-util";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";

export interface UserState {
  items: User[];
  pagination: Pagination;
  loading: boolean;
  callingAPI: boolean;
  searching: string;
  user: User;
  token: string;
  // mode: string;
  userInfo: UserInfo;
  signedIn: boolean;
  permisos: {};
}

@Module({ store, dynamic: true, name: "userModule" })
class UserModule extends VuexModule implements UserState {
  public callingAPI = false;
  public searching = "";
  user = getUser();
  token = getToken();

  public userInfo = {} as UserInfo;
  public signedIn = false;

  public items: User[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public permisos = {} as any;
  private timeoutId: number;


  get isSignedIn(): boolean {
    //console.log(this.user.username)
    //console.log(this.token);
    return this.user.username && this.token ? true : false;
  }

  @Mutation
  private loginLoading() {
    this.callingAPI = !this.callingAPI;
  }
  @Mutation
  private globalSearching() {
    this.searching = this.searching === "" ? "loading" : "";
  }

  @Mutation
  private setUser(_user: User): void {
    this.user = _user;
  }

  @Mutation
  private setToken(_token: string) {
    this.token = _token;
  }

  @Mutation
  private setUserInfo(_userInfo: UserInfo) {
    this.userInfo = _userInfo;
  }

  @Action({ rawError: true })
  public async signIn(userInfo: { usr: string; pwd: string }) {
    //const { usr, pwd } = userInfo;
    const data = await login("sesion/log_in", userInfo);
    const accessToken= data.data.accessToken;
    let error= false;
    //console.log(accessToken);
    if(accessToken){
      this.setTimeout();
      //console.log(accessToken);
      setToken(accessToken);
      const user= {} as User;
      user.username= userInfo.usr;
      user.role= data.data.role;
      user.id= data.data.id;
      //console.log(user);
      setUser(JSON.stringify(user));

      this.setToken(accessToken);
      this.setUser(user);
    }
    else{
      error= true;
    }
    return error;
  }

  @Action({ rawError: true })
  public getUsers() {
    this.setLoading(true);
    getData("usuarios/")
      .then(res  => {
        const usuarios = res.data;
        this.setDataTable(usuarios);
        this.setLoading(false);
      })
      .catch((err: TODO) => {
        appModule.sendErrorNotice(
          "Operation failed! Please try again later. "
        );
        appModule.closeNoticeWithDelay(1500);
      });
  }

  @Action({ rawError: true })
  async createUser(data) {
    await postData("sesion/sign_in/", data)
      .then(res  => {
        appModule.sendSuccessNotice("Nuevo usuario añadido.");
      })
      .catch((err: TODO) => {
        appModule.sendErrorNotice(
          "Operation failed! Please try again later. "
        );
        appModule.closeNoticeWithDelay(1500);
      });
  }

  @Action({ rawError: true })
  public getUser(id: string) {
    this.setLoading(true);
    getData("usuarios/"+id)
      .then(res  => {
      })
      .catch((err: TODO) => {
        appModule.sendErrorNotice(
          "Operation failed! Please try again later. "
        );
        appModule.closeNoticeWithDelay(1500);
      });
  }

  @Action({ rawError: true })
  async getPermisos(id: string) {
    if(id){
      await getData("permisos/"+id)
        .then(res  => {
          this.setUserPermisos(res.data[0]);
        })
        .catch((err: TODO) => {
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action({ rawError: true })
  setPermisos(data) {
    const idUser= data.id;
    const permisos= data.permisos;
    putData("permisos/"+idUser, permisos)
      .then(res  => {
        appModule.sendSuccessNotice("Permisos usuario actualizado.");
      })
      .catch((err: TODO) => {
        appModule.sendErrorNotice(
          "Operation failed! Please try again later. "
        );
        appModule.closeNoticeWithDelay(1500);
      });
  }

  @Action setDataTable(items: User[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Action({ rawError: true })
  logout() {
    this.setToken("");
    this.setUser({} as User);
    cleanSession();
  }


  @Action({ rawError: true })
  setTimeout() {
    this.timeoutId = setTimeout(() => {
      //TODO: cambiarlo por controlar error de autorizado
      //console.log(1);
      this.logout();
      window.location.href = '/login';
    }, 10* 60 * 60 * 1000); // 30 minutos en milisegundos
  }

  @Action({ rawError: true })
  resetTimeout() {
    clearTimeout(this.timeoutId);
    this.setTimeout();
  }

  @Action
  async deleteUsuario(id: number) {
    await deleteData(`usuarios/${id.toString()}`);
  }

  @Mutation
  setItems(usuarios: User[]) {
    this.items = usuarios;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }

  @Mutation
  setUserPermisos(permisos: {}) {
    this.permisos = permisos;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
}

export const userModule = getModule(UserModule);
