import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import {es, fr} from './lang/lang'

Vue.use(VueI18n)

let lang= "es";
if(navigator.language.includes("fr")){
  lang= navigator.language;
}

const i18n = new VueI18n({
  locale: lang, // El idioma predeterminado
  messages: {
    en: fr,
    es: es
  },
})


import VueProgressBar from 'vue-progressbar';

const options = {
  color: 'blue', // '#bffaf3',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.1s',
    opacity: '0.3s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};

Vue.config.productionTip = false;

Vue.use(VueProgressBar, options);

export default i18n;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n
}).$mount('#app');
