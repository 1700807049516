
















































































import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { vehiculoModule } from '@/store/modules/vehiculos';
import { neumaticoModule } from '@/store/modules/neumaticos';
import { appModule } from '@/store/modules/app';
import axios from "axios";

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog
  }
})
export default class VehiculoForm extends Vue {
 
  private orderDateMenu = false;
  private shippedDateMenu = false;
  private errors = [];
  private title = '';
  private productId = null;
  private categoryId = 0;
  private color = '';
  private selectedProduct: null;
  private data: [];
  public headers = [
    { text: 'Nombre', value: 'nombre' },
    { text: 'Fecha', value: 'fecha' },
    { text: '', value: 'actions', sortable: false }
  ];
  private URL: '';

  get neumatico() {
    return neumaticoModule.neumatico;
  }

  get allTiposNeumaticos() {
    console.log(neumaticoModule)
    return neumaticoModule.tiposNeumatico;
  }

  get allPosicionEje() {
      console.log(vehiculoModule.vehiculo.tipo_vehiculo)
      const tipoVehiculo= vehiculoModule.vehiculo.tipo_vehiculo;
      let posicion= [];
      if(tipoVehiculo == 1){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2IE', value:"2IE"}, { text:'2II', value:"2II"}, { text:'2DI', value:"2DI"},{ text:'2DE', value:"2DE"}];
      }
      else if(tipoVehiculo == 2){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2I', value:"2I"}, { text:'2D', value:"2D"}, { text:'3I', value:"3I"},{ text:'3D', value:"3D"}];
      }
      else if(tipoVehiculo == 3){
        posicion= [{ text:'1I', value:"1I"}, { text:'1D', value:"1D"}, { text:'2I', value:"2I"}, { text:'2D', value:"2D"}];
      }
      return posicion;
  }

  /*get pagination() {
    return neumaticoModule.neumatico.servicios.length;
  }*/

  edit(item) {
    this.$router.push(`/servicios_vehiculo/${item.id}`);
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  save() {
    //console.log(this.$refs.form.validate())
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      const neumatico = { ...this.neumatico };
      neumaticoModule.saveNeumatico(this.neumatico);
    }
  }

  getNeumaticoById() {
    neumaticoModule.getNeumaticoById(this.$route.params.id);
  }

  cancel() {
    this.$router.push({ name: 'neumaticos' });
  }

  /*remove(item) {
    this.selectedProduct = item;
    this.dialog = true;
  }

  onConfirm() {
    neumaticoModule.deleteProduct(this.selectedProduct);
    this.selectedProduct = null;
    this.dialog = false;
  }

  onCancel() {
    this.selectedProduct = null;
    this.dialog = false;
  }*/

  closeSnackbar() {
    appModule.closeNotice();
  }

  created() {
    // this.getCustomers();
    //neumaticoModule.getAllBases();
    this.getNeumaticoById();
  }

  mounted() {
    if (this.$route.params.id) {
      this.title = 'Editar Vehículo';
      this.$nextTick(() => {
        // this.shippedDate = this.order.shippedDate;
        // this.orderDate = this.order.orderDate;
      });
    } else this.title = 'Nuevo neumático';
  }
}
