import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Flota, Base } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";
import { get } from "lodash";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface BaseState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  base: Base;
  flotas: Flota[];
}

@Module({ store, dynamic: true, name: "bases" })
class BaseModule extends VuexModule implements BaseState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public base = {} as Base;
  public flotas: Flota[] = [];


  @Action
  getAllFlotasSelect() {
    getData("flotas").then(res => {
      const flotas = res.data;
      console.log(flotas)
      this.setFlotas(flotas);
    });
  }

  @Action
  async getBaseById(id: string) {
    if (id) {
      await getData("bases/" + id).then(
        res  => {
          const base = res.data[0];
          this.setBase(base);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const base = {} as Base
      this.setBase(base);
    }
  }

  @Action
  getAllBases() {
    this.setLoading(true);
    getData("bases").then(res => {
      const bases = res.data;
      console.log(bases);
      this.setDataTable(bases);
      this.setLoading(false);
    });
  }

  @Action
  getBaseByIdFlota(id: string) {
    if (id) {
      this.setLoading(true);
      getData("flotas/" + id+ "/bases").then(
        res  => {
          const bases = res.data;
          //console.log(bases);
          this.setDataTable(bases);
          this.setLoading(false);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const base = {} as Base
      this.setBase(base);
    }
  }

  @Action
  async deleteBase(id: number) {
    /*deleteData(`bases/${id.toString()}`)
      .then(res => {
        appModule.sendSuccessNotice("Base borrada correctamente.");
        this.getBaseByIdFlota(this.$route.params.id);
      })
      .catch((err: TODO) => {
        console.log(err);
        appModule.sendErrorNotice("Operation failed! Please try again later. ");
        appModule.closeNoticeWithDelay(1500);
      });*/
      await deleteData(`bases/${id.toString()}`);
  }
  @Action saveBase(base: Base) {

    if (!base.id) {
      postData("bases/", base)
        .then(res  => {
          console.log(res);
          //const base = res.data;
          this.setBase(base);
          appModule.sendSuccessNotice("New base has been added.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("bases/" + base.id.toString(), base)
        .then(res  => {
          const base = res.data;
          //this.setBase(base);
          appModule.sendSuccessNotice("Base has been updated.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action setDataTable(items: Base[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation 
  setFlotas(flotas: Flota[]) {
    this.flotas = flotas;
  }

  @Mutation
  setItems(bases: Base[]) {
    this.items = bases;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setBase(base: Base) {
    this.base = base;
  }
}

export const baseModule = getModule(BaseModule); // Orders;
