import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Tire, TiposNeumatico } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";
import { get } from "lodash";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { vehiculoModule } from '@/store/modules/vehiculos';
import { servicioModule } from '@/store/modules/servicios';

export interface NeumaticoState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  neumatico: Tire;
  tiposNeumatico: TiposNeumatico[];
}

@Module({ store, dynamic: true, name: "neumaticos" })
class NeumaticoModule extends VuexModule implements NeumaticoState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public neumatico = {} as Tire;
  public tiposNeumatico: TiposNeumatico[] = [];

  @Action
  getNeumaticoById(id: string) {
    if (id) {
      getData("neumaticos/" + id).then(
        res  => {
          const neumatico = res.data;
          this.setNeumatico(neumatico);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const neumatico = {} as Tire
      this.setNeumatico(neumatico);
    }
  }

  @Action
  getAllNeumaticos() {
    this.setLoading(true);
    getData("neumaticos").then(res => {
      const neumaticos = res.data;
      this.setDataTable(neumaticos);
      this.setLoading(false);
    });
  }

  @Action
  getTiposNeumatico() {
      this.setLoading(true);
      getData("tipos_neumatico").then(res => {
        const tiposNeumatico = res.data;
        this.setTiposNeumatico(tiposNeumatico);
        this.setLoading(false);
      });
  }

  @Action
  deleteNeumatico(id: number) {
    deleteData(`neumaticos/${id.toString()}`)
      .then(res => {
        appModule.sendSuccessNotice("Operation is done.");
      })
      .catch((err: TODO) => {
        appModule.sendErrorNotice("Operation failed! Please try again later. ");
        appModule.closeNoticeWithDelay(1500);
      });
  }
  @Action saveNeumatico(neumatico: Tire) {

    if (!neumatico.id) {
      postData("neumaticos/", neumatico)
        .then(res  => {
          //console.log(res);
          const id= res.data.insertId;
          neumatico.id= id;
          appModule.sendSuccessNotice("Nuevo neumático añadido.");
          //vehiculoModule.getNeumaticosByIdVehiculo(neumatico.id_vehiculo);
          vehiculoModule.getVehiculoById(vehiculoModule.vehiculo.id);
        })
        .catch((err: TODO) => {
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("neumaticos/" + neumatico.id.toString(), neumatico)
        .then(res  => {
          const neumaticos = res.data;
          //this.setVehiculo(vehiculo);
          appModule.sendSuccessNotice("Neumático ha sido actualizado.");
          vehiculoModule.getNeumaticosByIdVehiculo(neumatico.id_vehiculo);
        })
        .catch((err: TODO) => {
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
    //vehiculoModule.getNeumaticosByIdVehiculo(neumatico.id_vehiculo);
  }

  @Action setDataTable(items: Tire[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setItems(neumaticos: Tire[]) {
    this.items = neumaticos;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setNeumatico(neumatico: Tire) {
    this.neumatico = neumatico;
  }

  @Mutation
  setTiposNeumatico(tiposNeumatico: TiposNeumatico[]) {
    this.tiposNeumatico = tiposNeumatico;
  }
}

export const neumaticoModule = getModule(NeumaticoModule); // Orders;
