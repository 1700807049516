import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { userModule } from "@/store/modules/user";
import ErrorPage from "@/components/404.vue";
import Dashboard from "@/pages/Dashboard.vue";
import FlotaList from "@/pages/FlotaList.vue";
import FlotaForm from "@/pages/FlotaForm.vue";
import BaseList from "@/pages/BaseList.vue";
import BaseForm from "@/pages/BaseForm.vue";
import VehiculoList from "@/pages/VehiculoList.vue";
import VehiculoForm from "@/pages/VehiculoForm.vue";
import NeumaticoForm from "@/pages/NeumaticoForm.vue";
import InspeccionForm from "@/pages/InspeccionForm.vue";
import InformesForm from "@/pages/InformesForm.vue";
import PermisosForm from "@/pages/PermisosForm.vue";
import UsuarioList from "@/pages/UsuarioList.vue";
import UsuarioForm from "@/pages/UsuarioForm.vue";
import About from "@/pages/About.vue";
import Products from "@/pages/ProductList.vue";
import ProductForm from "@/pages/ProductForm.vue";
import Login from "@/pages/Login.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import ImportarNeumaticosForm from "@/pages/ImportarNeumaticosForm.vue";
import InformeFleetService from "@/pages/InformeFleetService.vue";

function requireAuth(to: TODO, from: TODO, next: TODO) {
  console.log(`userModule.isSignedI ${userModule.isSignedIn}`);
  if (!userModule.isSignedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/404", component: ErrorPage, name: "ErrorPage" },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
    beforeEnter: requireAuth
  },
  { path: "/about", component: About, name: "about", beforeEnter: requireAuth },
  {
    path: "/flotas",
    component: FlotaList,
    name: "Flotas",
    beforeEnter: requireAuth
  },
  {
    path: "/newflota",
    component: FlotaForm,
    name: "NewFlota",
    beforeEnter: requireAuth
  },
  {
    path: "/flota/:id",
    component: FlotaForm,
    name: "Flota",
    beforeEnter: requireAuth
  },
  {
    path: "/flota/:id/bases",
    component: BaseList,
    name: "bases",
    beforeEnter: requireAuth
  },
  {
    path: "/base/:id",
    component: BaseForm,
    name: "Base",
    beforeEnter: requireAuth
  },
  {
    path: "/newbase",
    component: BaseForm,
    name: "NewBase",
    beforeEnter: requireAuth
  },
  {
    path: "/bases/:id/vehiculos",
    component: VehiculoList,
    name: "vehiculos",
    beforeEnter: requireAuth
  },
  {
    path: "/base/:id_base/vehiculo/:id",
    component: VehiculoForm,
    name: "Vehiculo",
    beforeEnter: requireAuth
  },
  /*{
    path: "/servicios_vehiculo/:id",
    component: VehiculoForm,
    name: "Vehiculo",
    beforeEnter: requireAuth
  },*/
  {
    path: "/base/:id_base/vehiculos/newvehiculo",
    component: VehiculoForm,
    name: "NewVehiculo",
    beforeEnter: requireAuth
  },
  {
    path: "/vehiculo/:id/newneumatico",
    component: NeumaticoForm,
    name: "NewNeumatico",
    beforeEnter: requireAuth
  },
    {
    path: "/vehiculo/:id/inspeccion/:id_inspeccion",
    component: InspeccionForm,
    name: "Inspeccion",
    beforeEnter: requireAuth
  },
  {
    path: "/vehiculo/:id/newinspeccion",
    component: InspeccionForm,
    name: "NewInspeccion",
    beforeEnter: requireAuth
  },
  {
    path: "/informes/:tipo_informe",
    component: InformesForm,
    name: "Informes",
    beforeEnter: requireAuth
  },
  /*{
    path: "/permisos",
    component: PermisosForm,
    name: "Permisos",
    beforeEnter: requireAuth
  },*/
  {
    path: "/usuarios",
    component: UsuarioList,
    name: "Usuarios",
    beforeEnter: requireAuth
  },
  {
    path: "/usuario/newusuario",
    component: UsuarioForm,
    name: "NewUsuario",
    beforeEnter: requireAuth
  },
  {
    path: "/usuario/:id",
    component: UsuarioForm,
    name: "Usuario",
    beforeEnter: requireAuth
  },
  {
    path: "/usuario/:id/permisos",
    component: PermisosForm,
    name: "Permisos",
    beforeEnter: requireAuth
  },
  {
    path: "/importar-neumaticos",
    component: ImportarNeumaticosForm,
    name: "Importar neumáticos",
    beforeEnter: requireAuth
  },
  {
    path: "/informe-fleet-service/:id",
    component: InformeFleetService,
    name: "Informe Fleet service",
    beforeEnter: requireAuth
  },
  { path: "/login", component: Login, name: "Login" },
  {
    path: "/changePassword",
    component: ChangePassword,
    name: "ChangePassword"
  },
  { path: "/", redirect: "/flotas" },
  { path: "*", redirect: "/404" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
