import axios from "axios";
import { userModule } from "@/store/modules/user";
//const API_URL = "http://localhost:3000/";
const API_URL = "https://api.zen-bouman.217-148-136-176.plesk.page/";
import {getToken} from "@/utils/app-util";

const instance = axios.create({
  baseURL: API_URL,
  // timeout: false,
  params: {}, // do not remove this, its added to add params later in the config
  headers: {
    "Authorization": "Bearer "+ getToken()
  }
});

instance.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Configurar interceptor de respuesta
instance.interceptors.response.use(
  (response) => {
    // Modificar la respuesta aquí
    // Realizar transformaciones en los datos recibidos, manejar errores, etc.
    return response;
  },
  (error) => {
    // Manejar errores de respuesta aquí
    //console.log(error.response.status);
    /*if(error.response.status==403){
      userModule.logout();
      window.location.href = '/login';
    }*/
    return Promise.reject(error);
  }
);


export default {
  getData(action) {
    let url = `${API_URL}`;
    url += action;
    return instance.get(url);
  },
  postData(action, data) {
    let url = `${API_URL}`;
    url += action;
    return instance.post(url, data);
  },
  putData(action, data) {
    let url = `${API_URL}`;
    url += action;
    return instance.put(url, data);
  },
  deleteData(action) {
    let url = `${API_URL}`;
    url += action;
    return instance.delete(url);
  },
  login(action, data) {
    console.log(1);
    let url = `${API_URL}`;
    url += action;
    return instance.get(url, data);
  }
};

export function getData(action: string): Promise<TODO> {
  let url = `${API_URL}`;
  url += action;
  return instance.get(url);
}


export function postData(action, data): Promise<TODO> {
  let url = `${API_URL}`;
  url += action;
  return instance.post(url, data);
}

export function putData(action, data): Promise<TODO> {
  let url = `${API_URL}`;
  url += action;
  return instance.put(url, data);
}

export function deleteData(action): Promise<TODO> {
  let url = `${API_URL}`;
  url += action;
  return instance.delete(url);
}

export function login(action, data): Promise<TODO> {
  let url = `${API_URL}`;
  url += action;
  return instance.post(url, data);
}

