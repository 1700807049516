
































import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import SearchPanel from '@/components/SearchPanel.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { debounce } from 'lodash';
import { buildSearchFilters, buildJsonServerQuery, clearSearchFilters } from '@/utils/app-util';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { vehiculoModule } from '@/store/modules/vehiculos';
import { appModule } from '@/store/modules/app';
import { baseModule } from '@/store/modules/bases';
import { informeModule } from '@/store/modules/informes';
import TableReporteFlota from '@/components/TableReporteFlota.vue';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    SearchPanel,
    ConfirmDialog,
    TableReporteFlota
  }
})
export default class BaseList extends Vue {
  public dialog = false;
  public dialogTitle = 'Borrar vehículo';
  public dialogText = '¿Quieres borrar este vehículo?';
  public showSearchPanel = false;
  public right = true;
  public search = '';
  public headers = [
    { text: 'Matrícula', value: 'matricula' },
    { text: '', value: 'actions', sortable: false }
  ];
  private searchFilter = {
    contain: {
      reference: '',
      customer: ''
    },
    greaterThanOrEqual:{
      amount: 0
    },
    lessThanOrEqual:{
      amount: 0
    },
  };
  // private orderId = '';
  private query = '';
  private color = '';
  private itemId = -1;

  edit(item) {
    this.$router.push(`/base/${this.$route.params.id}/vehiculo/${item.id}`);
  }
  view(item) {
    this.$router.push(`/base/${this.$route.params.id}/vehiculo/${item.id}`);
  }
  add() {
    this.$router.push(`/base/${this.$route.params.id}/vehiculos/NewVehiculo`);
  }
  remove(item) {
    this.itemId = item.id;
    this.dialog = true;
  }
  onConfirm() {
    vehiculoModule.deleteVehiculo(this.itemId).then(data=>{
      this.getVehiculoByIdBase();
      appModule.sendSuccessNotice("Vehículo borrado correctamente.");
    });
    this.dialog = false;
  }
  onCancel() {
    this.itemId = -1;
    this.dialog = false;
  }

  getVehiculoByIdBase() {
    vehiculoModule.getVehiculoByIdBase(this.$route.params.id);
  }


  clearSearchFilters() {
    this.showSearchPanel = !this.showSearchPanel;
    clearSearchFilters(this.searchFilter);
    this.getVehiculoByIdBase();
  }

  reloadData() {
    this.query = '';
    this.getVehiculoByIdBase();
  }

   updateSearchPanel(){
    this.rightDrawer=!this.rightDrawer;
  }

  cancelSearch() {
    this.showSearchPanel = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  get informe() {
    return informeModule.informe.json;
  }

  get nombreBase() {
    return baseModule.base.nombre;
  }

  get idFlota() {
    return baseModule.base.id_flota;
  }

  get items() {
    return vehiculoModule.items;
  }
  get pagination() {
    return vehiculoModule.pagination;
  }
  get loading() {
    return appModule.loading;
  }
  get mode() {
    return appModule.mode;
  }
  get snackbar() {
    return appModule.snackbar;
  }
  get notice() {
    return appModule.notice;
  }

  get rightDrawer() {
    return this.showSearchPanel;
  }

  set rightDrawer(_showSearchPanel: boolean) {
    this.showSearchPanel = _showSearchPanel;
  }

  async created() {
    if (this.$route.params.id) {
      const idFlota= this.$route.params.id;
      //const nombreFlota= this.allFlotas.find(flota => flota.id == idFlota).nombre;
      const tipoInforme= "reporte_flota";
      const informe= {
        "tipo_informe": tipoInforme,
        "id_flota": idFlota
      };
      await informeModule.getInforme(informe);
      console.log(this.informe);
    }
  }
  mounted() {
    this.dialogTitle = this.$t("message.Borrar vehículo");
    this.dialogText = this.$t("message.¿Quieres borrar este vehículo?");
    this.headers = [
      { text: this.$t("message.Matrícula"), value: 'matricula' },
      { text: '', value: 'actions', sortable: false }
    ];
    // this.$nextTick(() => {
    //   console.log(this.headers);
    // });
  }
}
