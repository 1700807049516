import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Informe } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";
import { get } from "lodash";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface InformeState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  informe: Informe;
}

@Module({ store, dynamic: true, name: "informes" })
class InformeModule extends VuexModule implements InformeState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public informe = {} as Informe;

  @Action
  getCalendarioInspeccionVehiculo(informe: Informe) {
    if (informe) {
      const json= {
        "fecha_inicio": informe.fecha_inicio,
        "fecha_fin": informe.fecha_fin,
        "id_flota": informe.id_flota
      };
      postData("calendario_inspeccion_vehiculo/", json)
        .then(res  => {
          console.log(res);
          informe.json= res.data;
          this.setInforme(informe);
          //const id= res.data.data.insertId;
          //neumatico.id= id;
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      const informe = {} as Informe
      this.setInforme(informe);
    }
  }

  @Action
  async getInforme(informe: Informe) {
    if (informe) {
      if(informe.tipo_informe== "reporte_flota"){
        await getData(informe.tipo_informe+"/"+informe.id_flota)
          .then(res  => {
            console.log(res);
            informe.json= res.data[0];
            this.setInforme(informe);
            //const id= res.data.data.insertId;
            //neumatico.id= id;
          })
          .catch((err: TODO) => {
            console.log(err);
            appModule.sendErrorNotice(
              "Operation failed! Please try again later. "
            );
            appModule.closeNoticeWithDelay(1500);
          });
      }
      else{
        const json= {
          "fecha_inicio": informe.fecha_inicio,
          "fecha_fin": informe.fecha_fin,
          "id_flota": informe.id_flota
        };
        await postData(informe.tipo_informe+"/", json)
          .then(res  => {
            console.log(res);
            informe.json= res.data;
            this.setInforme(informe);
            //const id= res.data.data.insertId;
            //neumatico.id= id;
          })
          .catch((err: TODO) => {
            console.log(err);
            appModule.sendErrorNotice(
              "Operation failed! Please try again later. "
            );
            appModule.closeNoticeWithDelay(1500);
          });
      }
    } else {
      const informe = {} as Informe
      this.setInforme(informe);
    }
  }

  /*@Action setDataTable(items: Informe[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setItems(informes: Informe[]) {
    this.items = informes;
  }*/
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setInforme(informe: Informe) {
    this.informe = informe;
  }
}

export const informeModule = getModule(InformeModule); // Orders;
