import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Servicio, ServicioNeumatico } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { get } from "lodash";
import { appModule } from '@/store/modules/app';
import { vehiculoModule } from '@/store/modules/vehiculos';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import i18n from '@/main';

export interface ServicioState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  servicio: Servicio;
  servicioNeumatico: ServicioNeumatico;
}

@Module({ store, dynamic: true, name: "servicios" })
class ServicioModule extends VuexModule implements ServicioState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public servicio = {} as Servicio;
  public servicioNeumatico = {} as ServicioNeumatico;

  @Action
  getServicioById(id: string) {
    if (id) {
      getData("servicios_vehiculo/" + id).then(
        res  => {
          const servicio = res.data;
          this.setServicio(servicio);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const servicio = {} as Servicio
      this.setServicio(servicio);
    }
  }

  @Action
  getAllServicios() {
    this.setLoading(true);
    getData("servicios_vehiculo").then(res => {
      const servicios = res.data;
      console.log(servicios);
      this.setDataTable(servicios);
      this.setLoading(false);
    });
  }

  @Action
  deleteServicio(id: number) {
    deleteData(`servicios_vehiculo/${id.toString()}`)
      .then(res => {
        appModule.sendSuccessNotice("Servicio eliminado correctamente.");
      })
      .catch((err: TODO) => {
        console.log(err);
        appModule.sendErrorNotice("Operation failed! Please try again later. ");
        appModule.closeNoticeWithDelay(1500);
      });
  }
  @Action saveServicio(servicio: Servicio) {

    if (!servicio.id) {
      postData("servicios_vehiculo/", servicio)
        .then(res  => {
          console.log(res);
          const id= res.data.insertId;
          servicio.id= id;
          const id_vehiculo= servicio.id_vehiculo;
          console.log(servicio);
          //this.setServicio(servicio);
          appModule.sendSuccessNotice(i18n.t('message.Nuevo servicio ha sido añadido').toString());
          appModule.setModal(false);
          vehiculoModule.getVehiculoById(id_vehiculo);
          //location.reload();
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("servicios_vehiculo/" + servicio.id.toString(), servicio)
        .then(res  => {
          const servicio = res.data;
          //this.setVehiculo(vehiculo);
          appModule.sendSuccessNotice("Servicio has been updated.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action saveServicioNeumatico(servicio: ServicioNeumatico) {

    if (!servicio.id) {
      postData("servicios_neumatico/", servicio)
        .then(res  => {
          console.log(res);
          const id= res.data.insertId;
          servicio.id= id;
          console.log(servicio);
          //this.setServicioNeumatico(servicio);
          appModule.sendSuccessNotice(i18n.t('message.Nuevo servicio ha sido añadido').toString());
          vehiculoModule.getVehiculoById(vehiculoModule.vehiculo.id);
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("servicios_neumatico/" + servicio.id.toString(), servicio)
        .then(res  => {
          const servicio = res.data;
          //this.setVehiculo(vehiculo);
          appModule.sendSuccessNotice("Servicio has been updated.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action setDataTable(items: Servicio[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setItems(servicios: Servicio[]) {
    this.items = servicios;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setServicio(servicio: Servicio) {
    this.servicio = servicio;
  }
  @Mutation
  setServicioNeumatico(servicio: ServicioNeumatico) {
    this.servicioNeumatico = servicio;
  }
}

export const servicioModule = getModule(ServicioModule); // Orders;
