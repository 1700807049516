





































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Tire } from "@/types";

@Component({})
export default class TireBox extends Vue {
  @Prop({ default: {} }) tireInfo!: Tire;
  @Prop({ default: false }) angled: boolean;
  @Prop({ default: false }) rotated: boolean;
  @Prop({ default: false }) horizontal: boolean;
  @Prop({ default: false }) inspeccion: boolean;

  sendEventAddTire(tirePosition: string) {
    this.$emit("addTireEvent", this.tireInfo);
  }

  roundTwoDecimals(num: number): number {
    return Math.round(num * 100) / 100;
  }
}
