

















































import Table from '@/components/Table.vue';
import TableHeaderButtons from '@/components/TableHeaderButtons.vue'
import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { Component, Watch } from 'vue-property-decorator';
import { flotaModule } from '@/store/modules/flotas';
import { appModule } from '@/store/modules/app';
import { baseModule } from '@/store/modules/bases';
import { userModule } from '@/store/modules/user';

@Component({
  components: {
    Table,
    TableHeaderButtons,
    ConfirmDialog
  }
})
export default class InformeForm extends Vue {
  private errors = [];
  private title = 'Permisos flotas y bases';
  private data: [];
  private dialog = false;
  private bases: boolean[] = [];
  private flotas: boolean[] = [];

  get allFlotas() {
    return flotaModule.items;
  }

  get allBases() {
    return baseModule.items;
  }

  get loading() {
    return appModule.loading;
  }

  get mode() {
    return appModule.mode;
  }

  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  save() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      const selectedBases = [];
      const selectedFlotas = [];
      for(const baseId in this.bases){
        if(this.bases[baseId]){
          selectedBases.push(baseId);
        }
      }
      for(const flotaId in this.flotas){
        if(this.flotas[flotaId]){
          selectedFlotas.push(flotaId);
        }
      }
      console.log(selectedBases);
      console.log(selectedFlotas);
      console.log(this.$route.params.id);
      const data= {} as any;
      data.id= this.$route.params.id;
      data.permisos={flotas:[], bases:[]};
      data.permisos.flotas= selectedFlotas;
      data.permisos.bases= selectedBases;
      userModule.setPermisos(data);
    }
  }

  cancel() {
    this.$router.push(`/usuarios`);
  }

  created() {
    appModule.setModal(false);
    flotaModule.getAllFlotas();
    //baseModule.getAllBases();
  }

  async mounted() {
    await userModule.getPermisos(this.$route.params.id);
    console.log(userModule.permisos);
    userModule.permisos.flotas.forEach(flota=> this.$set(this.flotas, flota, true));
    userModule.permisos.bases.forEach(base=> this.$set(this.bases, base, true));
  }
}
