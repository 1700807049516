


























































import Vue from "vue";
import { Entity, Servicio } from "@/types";
import { Component, Prop } from "vue-property-decorator";
import { servicioModule } from '@/store/modules/servicios';
import { appModule } from '@/store/modules/app';
import { userModule } from '@/store/modules/user';

@Component
export default class ServicioVehiculo extends Vue {
  @Prop() readonly headers: TableHeader[];
  @Prop() readonly items: Entity[];
  @Prop() readonly pagination: Pagination;
  @Prop() readonly isRemovable: boolean;
  @Prop() addServicioModal: boolean;
  @Prop() idVehiculo: number;

  search = "";

  get servicio() {
    return servicioModule.servicio;
  }

  get tiposServicio() {
    const tiposServicio= [{ text:this.$t("message.Alineacion dirección"), value:8}, { text:this.$t("message.Alineacion doble dirección"), value:9}, { text:this.$t("message.Comprobacion alineación dirección"), value:10}, { text:this.$t("message.Salidas coche taller"), value:11}];
    return tiposServicio;
  }

  get modal() {
    return appModule.modal;
  }

  isNotEmpty() {
    return this.items && this.items.length > 0;
  }

  hasHeader(header: TODO) {
    return header?.value ? true : false;
  }

  saveServicio() {
    //console.log(this.$refs.form.validate())
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()){
      //console.log(this.idVehiculo);
      let servicio = { ...this.servicio };
      servicio.id_vehiculo= this.idVehiculo;
      servicio.id_proveedor_servicio= userModule.user.id;
      /*const fecha= new Date().toISOString();
      servicio.fecha= fecha.split('T')[0]+" "+ fecha.split('T')[1].substring(0, 8);*/
      const fecha= new Date();
      fecha.setHours(fecha.getHours() + 2);
      servicio.fecha= fecha.toISOString().split('T')[0]+" "+ fecha.toISOString().split('T')[1].substring(0, 8);
      let km_recorrido= 0;
      if(servicio.km_recorrido){
        km_recorrido= servicio.km_recorrido;
      }
      servicio.km_recorrido= km_recorrido;
      //console.log(servicio);
      servicioModule.saveServicio(servicio);
      (this.$refs.form as any).resetValidation();
      servicio = {} as Servicio;
      servicioModule.setServicio(servicio);
    }
  }

  cancelAddServicio() {
    //console.log(this.$refs.form.resetValidation());
    (this.$refs.form as any).resetValidation();
    appModule.setModal(false);
    const servicio = {} as Servicio
    servicioModule.setServicio(servicio);
  }
}
