import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Flota } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";
import { get } from "lodash";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface FlotaState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  flota: Flota;
}

@Module({ store, dynamic: true, name: "flotas" })
class FlotaModule extends VuexModule implements FlotaState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public flota = {} as Flota;

  @Action
  getFlotaById(id: string) {
    if (id) {
      getData("flotas/" + id).then(
        res  => {
          const flota = res.data[0];
          this.setFlota(flota);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const flota = {} as Flota
      this.setFlota(flota);
    }
  }

  @Action
  getAllFlotas() {
    this.setLoading(true);
    getData("flotas").then(res => {
      const flotas = res.data;
      console.log(flotas);
      this.setDataTable(flotas);
      this.setLoading(false);
      return flotas;
    });
  }

  @Action
  deleteFlota(id: number) {
    deleteData(`flotas/${id.toString()}`)
      .then(res => {
        appModule.sendSuccessNotice("Operation is done.");
      })
      .catch((err: TODO) => {
        console.log(err);
        appModule.sendErrorNotice("Operation failed! Please try again later. ");
        appModule.closeNoticeWithDelay(1500);
      });
  }
  @Action saveFlota(flota: Flota) {

    if (!flota.id) {
      console.log(flota)
      postData("flotas/", flota)
        .then(res  => {
          console.log(res);
          const flota = {
            id: 0,
            nombre: '',
            criterio_inspeccion: ''
          }
          this.setFlota(flota);
          appModule.sendSuccessNotice("New flota has been added.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("flotas/" + flota.id.toString(), flota)
        .then(res  => {
          const flota = res.data;
          this.setFlota(flota);
          appModule.sendSuccessNotice("Flota has been updated.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action setDataTable(items: Flota[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setItems(flotas: Flota[]) {
    this.items = flotas;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setFlota(flota: Flota) {
    this.flota = flota;
  }
}

export const flotaModule = getModule(FlotaModule); // Orders;
