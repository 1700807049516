import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Inspeccion, ServicioNeumatico } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { get } from "lodash";
import { appModule } from '@/store/modules/app';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface InspeccionState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  inspeccion: Inspeccion;
  neumaticos: any[];
}

@Module({ store, dynamic: true, name: "inspecciones" })
class InspeccionModule extends VuexModule implements InspeccionState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public inspeccion = {} as Inspeccion;
  public neumaticos: any[] = [];

  @Action
  async getInspeccionById(id: string) {
    if (id) {
      getData("inspecciones_vehiculo/" + id).then(
        res  => {
          const inspeccion = res.data[0];
          console.log(inspeccion)
          //inspeccion.neumaticos= JSON.parse(inspeccion.neumaticos);
          this.setNeumaticos(inspeccion.neumaticos);
          this.setInspeccion(inspeccion);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      const inspeccion = {} as Inspeccion;
      const neumaticos = [] as any;
      this.setInspeccion(inspeccion);
      this.setNeumaticos(neumaticos);
    }
  }

  @Action
  getAllInspecciones() {
    this.setLoading(true);
    getData("inspecciones_vehiculo").then(res => {
      const inspeccion = res.data;
      console.log(inspeccion);
      this.setDataTable(inspeccion);
      this.setLoading(false);
    });
  }

  @Action
  deleteInspeccion(id: number) {
    deleteData(`inspecciones_vehiculo/${id.toString()}`)
      .then(res => {
        appModule.sendSuccessNotice("Servicio eliminado correctamente.");
      })
      .catch((err: TODO) => {
        console.log(err);
        appModule.sendErrorNotice("Operation failed! Please try again later. ");
        appModule.closeNoticeWithDelay(1500);
      });
  }
  @Action saveInspeccion(inspeccion: Inspeccion) {

    if (!inspeccion.id) {
      postData("inspecciones_vehiculo/", inspeccion)
        .then(res  => {
          console.log(res);
          const id= res.data.insertId;
          console.log(id)
          inspeccion.id= id;
          const id_vehiculo= inspeccion.id_vehiculo;
          this.setInspeccion(inspeccion);
          appModule.sendSuccessNotice("Nueva inspección ha sido añadida.");
          appModule.setModal(false);
          this.setLoading(false);
          //vehiculoModule.getVehiculoById(id_vehiculo);
          //location.reload();
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      putData("inspecciones_vehiculo/" + inspeccion.id.toString(), inspeccion)
        .then(res  => {
          const inspeccion = res.data;
          //this.setVehiculo(vehiculo);
          appModule.sendSuccessNotice("Inspección ha sido actualizada.");
        })
        .catch((err: TODO) => {
          console.log(err);
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action setDataTable(items: Inspeccion[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setItems(inspecciones: Inspeccion[]) {
    this.items = inspecciones;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setInspeccion(inspeccion: Inspeccion) {
    this.inspeccion = inspeccion;
  }

  @Mutation
  setNeumaticos(neumaticos: any[]) {
    this.neumaticos = neumaticos;
  }
}

export const inspeccionModule = getModule(InspeccionModule); // Orders;
