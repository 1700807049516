var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tire-box d-flex flex-column align-center",attrs:{"id":("position_" + (_vm.tireInfo.posicion))}},[_c('v-img',{staticClass:"tire-img",class:{ rotated: _vm.rotated, angled: _vm.angled, horizontal: _vm.horizontal },attrs:{"max-height":"auto","src":require("@/assets/img/vehicleTires/RUEDA_1.png"),"id":"eje-neumatico"}}),_c('div',{staticClass:"tire-info-box d-flex",class:_vm.horizontal
        ? 'horizontal justify-space-between align-center'
        : 'flex-column align-center'},[_c('div',{staticClass:"pression-and-mm-info-box"},[_c('div',{staticClass:"custom-chip pression-info text-center",class:{
          invisible: !_vm.tireInfo.presion && _vm.tireInfo.presion !== 0,
          horizontal: _vm.horizontal
        }},[_vm._v(" "+_vm._s(_vm.tireInfo.presion ? _vm.roundTwoDecimals(_vm.tireInfo.presion) : "-")+" bar ")]),_c('div',{staticClass:"custom-chip mm-info text-center",class:{
          invisible: !_vm.tireInfo.mm && _vm.tireInfo.mm !== 0,
          horizontal: _vm.horizontal,
          'white--text': _vm.tireInfo.mm < 5,
          'red': _vm.tireInfo.mm < 5
        }},[_vm._v(" "+_vm._s(_vm.tireInfo.mm ? _vm.roundTwoDecimals(_vm.tireInfo.mm) : "- ")+"mm ")])]),_c('div',{staticClass:"tire-position-box d-flex align-center justify-center",class:{ 
        'mr-1': _vm.horizontal,
        'orange': _vm.tireInfo.reesculturado 
      }},[_c('b',{class:{ 'white--text': _vm.tireInfo.reesculturado }},[_vm._v(" "+_vm._s(_vm.tireInfo.posicion)+" ")])]),_c('v-btn',{staticClass:"mt-1",class:_vm.tireInfo.mm ? 'light-blue' : 'amber',attrs:{"elevation":"4","fab":"","small":"","dark":""},nativeOn:{"click":function($event){return _vm.sendEventAddTire(_vm.tireInfo.posicion)}}},[(_vm.tireInfo.mm)?_c('v-icon',[_vm._v("mdi-eye")]):_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }