
















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { userModule } from '@/store/modules/user';

@Component
export default class TableHeaderButtons extends Vue {
  @Prop() reloadData: () => void;
  @Prop() updateSearchPanel: () => void;
  @Prop() add: () => void;

  print() {
    window.print();
  }

  get user() {
    return userModule.user;
  }
}
