import { getData, putData, postData, deleteData } from "@/utils/backend-api";
import { Entity, Base, Vehiculo, Tire } from "@/types";
import { getDefaultPagination, getPagination } from "@/utils/store-util";
import { appModule } from "./app";
import { get } from "lodash";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { servicioModule } from '@/store/modules/servicios';
import i18n from '@/main';

export interface VehiculoState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  vehiculo: Vehiculo;
  bases: Base[];
}

@Module({ store, dynamic: true, name: "vehiculos" })
class VehiculoModule extends VuexModule implements VehiculoState {
  public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = false;
  public vehiculo = {} as Vehiculo;
  public bases: Base[] = [];

  @Action
  async getVehiculoById(id: number) {
    if (id) {
      await getData("vehiculos/" + id).then(
        res  => {
          const vehiculo = res.data;
          this.setVehiculo(vehiculo);
          vehiculo.servicios.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
          if(vehiculo.servicios){
            servicioModule.setDataTable(vehiculo.servicios);
          }
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      /*const baseVehicleData = {
        neumaticos: []
      };*/
      const vehiculo = {} as Vehiculo;
      vehiculo.neumaticos= [];
      this.setVehiculo(vehiculo);
    }
  }

  @Action
  getAllBasesByFlotaId(id: number) {
    getData("flotas/" + id+ "/bases").then(
      res  => {
        const bases = res.data;
        this.setBases(bases);
      },
      (err: TODO) => {
        console.log(err);
      }
    );
  }

  @Action
  getAllVehiculos() {
    this.setLoading(true);
    getData("vehiculos").then(res => {
      const vehiculos = res.data;
      this.setDataTable(vehiculos);
      this.setLoading(false);
    });
  }

  @Action
  getVehiculoByIdBase(id: string) {
    if (id) {
      this.setLoading(true);
      getData("bases/" + id+ "/vehiculos").then(
        res  => {
          const vehiculos = res.data;
          this.setDataTable(vehiculos);
          this.setLoading(false);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      //const vehiculo = {} as Vehiculo
      //this.setVehiculo(vehiculo);
    }
  }

  @Action
  getNeumaticosByIdVehiculo(id: number) {
    if (id) {
      this.setLoading(true);
      getData("vehiculos/" + id+ "/neumaticos").then(
        res  => {
          const neumaticos = res.data;
          console.log(neumaticos);
          this.setNeumaticos(neumaticos);
          this.setLoading(false);
        },
        (err: TODO) => {
          console.log(err);
        }
      );
    } else {
      //const vehiculo = {} as Vehiculo
      //this.setVehiculo(vehiculo);
    }
  }

  @Action
  async deleteVehiculo(id: number) {
    await deleteData(`vehiculos/${id.toString()}`);
  }
  @Action async saveVehiculo(vehiculo: Vehiculo) {

    if (!vehiculo.id) {
      //delete vehiculo["neumaticos"];
      postData("vehiculos/", vehiculo)
        .then(res  => {
          vehiculo.id= res.data.insertId;
          //vehiculo.neumaticos= [];
          this.getVehiculoById(vehiculo.id);
          //this.setVehiculo(vehiculo);
          appModule.sendSuccessNotice(i18n.t('message.Nuevo vehículo añadido').toString());
        })
        .catch((err: TODO) => {
          let error= "Operation failed! Please try again later. ";
          if(err.response.data){
            error= i18n.t('message.'+err.response.data).toString();
          }
          appModule.sendErrorNotice(
            error
          );
          appModule.closeNoticeWithDelay(1500);
        });
    } else {
      delete vehiculo["servicios"];
      delete vehiculo["fecha_proxima_inspeccion"];
      delete vehiculo["neumaticos"];
      putData("vehiculos/" + vehiculo.id.toString(), vehiculo)
        .then(res  => {
          const vehiculo = res.data;
          //this.setVehiculo(vehiculo);
          //console.log(i18n.t('message.Vehículo ha sido actualizado.'));
          appModule.sendSuccessNotice(i18n.t('message.Vehículo ha sido actualizado').toString());
        })
        .catch((err: TODO) => {
          appModule.sendErrorNotice(
            "Operation failed! Please try again later. "
          );
          appModule.closeNoticeWithDelay(1500);
        });
    }
  }

  @Action setDataTable(items: Vehiculo[]) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation
  setBases(bases: Base[]) {
    this.bases = bases;
  }

  @Mutation
  setItems(vehiculos: Vehiculo[]) {
    this.items = vehiculos;
  }
  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }
  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  @Mutation
  setVehiculo(vehiculo: Vehiculo) {
    this.vehiculo = vehiculo;
  }

  @Mutation
  setNeumaticos(neumaticos: Tire[]) {
    this.vehiculo.neumaticos = neumaticos;
  }
}

export const vehiculoModule = getModule(VehiculoModule); // Orders;
